import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import your router configuration
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';


Amplify.configure(awsconfig);
Amplify.Logger.LOG_LEVEL = 'DEBUG'; 
const app = createApp(App);

// Use the router configuration by passing it to the Vue app instance
app.use(router);

app.mount('#app');
