import { createRouter, createWebHistory } from 'vue-router';
import GrossSalesDetail from './views/GrossSalesDetail.vue';
import GrossSalesDetailTest from './views/GrossSalesDetailTest.vue';
import MemberData from './views/MemberData.vue';
import CognitoLogin from './views/CognitoLogin.vue';
import CognitoLogout from './components/CognitoLogout.vue';
import { Auth } from 'aws-amplify';

// Define your routes
const routes = [
  {
    path: '/login',
    component: CognitoLogin,
    name: 'CognitoLogin',
  },
  {
    path: '/logout',
    component: CognitoLogout,
    name: 'CognitoLogout',
  },
  {
    path: '/',
    component: GrossSalesDetail,
    name: 'GrossSalesDetail',
    meta: {
      requiresAuth: true, // Require authentication for this route
    },
  },
  {
    path: '/salesdev',
    component: GrossSalesDetailTest,
    name: 'GrossSalesDetailTest',
    meta: {
      requiresAuth: true, // Require authentication for this route
    },
  },
  {
    path: '/MemberData',
    component: MemberData,
    name: 'MemberData',
    meta: {
      requiresAuth: true, // Require authentication for this route
    },
  },
];

// Create and configure the router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    Auth.currentAuthenticatedUser()
      .then(() => {
        // console.log("User is authenticated", user); // Debug
        // User is authenticated, allow access
        next();
      })
      .catch(() => {
        // console.log("User is not authenticated", error);
        // User is not authenticated, redirect to login page
        next('/login');
      });
  } else {
    next();
  }
});


export default router; // Export the router instance
