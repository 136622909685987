<template>
  <div>
    <h2>Login</h2>
    <p>Redirecting to login page for authentication...</p>
  </div>
</template>

<script>
export default {
  mounted() {
    this.login();
  },
  methods: {
    async login() {
      const redirectUri = 'https://clubreadyupload.willowpointconsulting.com/';
      // const redirectUri = 'http://localhost:8080/';
      const clientId = '4s1g4oush8844vg9bitnvltuk5';
      const cognitoUrl = `https://clubreadyupload.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=email+openid+phone`;

      // Redirect to the Cognito hosted login page
      window.location.href = cognitoUrl;
    },
  },
};
</script>
