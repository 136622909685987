const awsmobile = {
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_j3u5Memxe',
    userPoolWebClientId: '4s1g4oush8844vg9bitnvltuk5',
    oauth: {
      domain: 'clubreadyupload.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'openid', 'phone'],
      redirectSignIn: 'https://clubreadyupload.willowpointconsulting.com/',
      redirectSignOut: 'https://clubreadyupload.willowpointconsulting.com/login',
      responseType: 'code'
    }
  }
};

export default awsmobile;
