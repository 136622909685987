<template>
    <div>
      <button @click="logout">Logout</button>
    </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
methods: {
    async logout() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.error("Error signing out: ", error);
    }
    },
},
};
</script>
  