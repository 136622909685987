<template>
  <div :class=classes>
    <div class="header-content">
      <div></div> <!-- Empty div for flex layout -->
      <div class="w-full text-2xl font-bold">
        <h1 class="ml-4 p-3">{{ title }}</h1>
      </div>
      <div class="flex items-center justify-end space-x-4">
        <span class="text-lg text-gray-600">Reports:</span>
        <select @change="navigateTo($event)" :value="selectedOption" 
                class="text-black bg-white border rounded-lg p-2 focus:outline-none focus:border-blue-500">
          <option v-for="route in routes" :key="route.name" :value="route.path">
            {{ route.name }}
          </option>
        </select>
        <CognitoLogout class="text-lg font-bold"></CognitoLogout>
      </div>
    </div>
  </div>
</template>

<script>
import CognitoLogout from "./CognitoLogout.vue";

export default {
  components: {
    CognitoLogout,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      default: 'bg-blue-500 text-white',
    },
    selectedOption: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      routes: [
        { name: 'Gross Sales Detail', path: '/' },
        { name: 'Member Data', path: '/MemberData' },
      ],
      classes: [
          'header-container',
          this.class
      ]
    };
  },
  methods: {
    navigateTo(event) {
      const path = event.target.value;
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.header-title {
  text-align: center;
}

select {
  cursor: pointer;
}
</style>
