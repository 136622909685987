<template>
    <AppHeader title="Club Ready - Gross Sales Detail Upload - DEV" :selectedOption="'/salesdev'"/>
    <div class="flex">
        <div class="w-1/3"> </div>
        <div class="flex flex-col items-center mt-32 w-1/3">
            <div v-if="hasError && !loading" class="text-red-600 mt-2">
                {{ errorMessage }}
            </div>
            <div v-if="hasSuccess  && !loading" class="text-green-600 mt-2">
                {{ successMessage }}
            </div>
            <div v-if="loading" class="text-black-600 mt-2">
                Loading...
            </div>
            <h1 class="text-2xl font-bold">Select Date Range</h1>
            <div class="flex items-center">
                <h2 class="text-lg mr-4 mt-2 w-28">Start Date</h2>
                <VueDatePicker v-model="startDate" :enable-time-picker="false" class="mt-4 w-24"/>
            </div>
            <div class="flex items-center">
                <h2 class="text-lg mr-4 mt-2 w-28">End Date</h2>
                <VueDatePicker v-model="endDate" :enable-time-picker="false" class="mt-4 w-24"/>
            </div>

            <h1 class="mt-4 text-2xl font-bold">Upload CSV</h1>
            <div class="mt-4 text-xl ml-20">
                <input type="file" ref="fileInput" @change="handleFileChange" />
            </div>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" @click="uploadFile">Upload</button>
            <h1 class="text-2xl font-bold mt-10">Actions</h1>
            <div v-if="runResults">
                <div v-if="hasSuccess && !hasError">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" @click="transformData">Transform Data</button>
                </div>
            </div>
        </div>
        <RunResults class="left-0 w-1/3 mt-32 ml-4" :resultsArray="runResults"/>
    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import axios from 'axios';
import AppHeader from '../components/AppHeader.vue';
import RunResults from '../components/RunResults.vue';
import { Auth } from 'aws-amplify';

export default {
  components: {
    VueDatePicker,
    AppHeader,
    RunResults,
  },
  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      uploadDate: new Date(),
      selectedFile: null,
      loadedFile: null,
      errorMessage: '',
      hasError: false,
      successMessage: '',
      hasSuccess: false,
      loading: false,
      uploadUuid: null,
      runResults: null
    };
  },
  methods: {
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async getCognitoToken() {
      try {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
      } catch (error) {
        console.log('Error getting token', error);
        return null;
      }
    },
    async uploadFile() {
      this.hasSuccess = false;
      this.hasError = false;
      this.startDate.setHours(0, 0, 0, 0);
      this.endDate.setHours(0, 0, 0, 0);
      this.uploadDate.setHours(0, 0, 0, 0);
      
      if (!this.selectedFile || this.selectedFile.type !== 'text/csv') {
        this.errorMessage = 'Please select a valid CSV file to upload.';
        this.hasError = true;
        this.hasSuccess = false;
        return
      }

      this.loading = true;

      const token = await this.getCognitoToken();

      if (!token) {
        this.errorMessage = 'Unable to get authentication token.';
        this.hasError = true;
        this.hasSuccess = false;
        return;
      }

      const headers = {
        'Authorization': token,
        'Content-Type': 'text/csv'
      };

      try {
        const response = await axios.post('https://s4qlol1gf7.execute-api.us-east-1.amazonaws.com/dev/upload', {
          file_name: this.selectedFile.name,
          upload_date: this.formatDate(this.uploadDate.toISOString()),
        }, { headers });
        const presignedUrl = response.data.presigned_url;

        await axios.put(presignedUrl, this.selectedFile, {
          headers: {
            'Content-Type': 'text/csv'
          }
        });

        // Check if response is successful
        if (response.status === 200) {
          const res = await axios.post('https://2e3dadnbej.execute-api.us-east-1.amazonaws.com/dev/upload/callback', {
            file_name: this.selectedFile.name,
            upload_date: this.formatDate(this.uploadDate.toISOString()),
            start_date: this.formatDate(this.startDate.toISOString()),
            end_date: this.formatDate(this.endDate.toISOString()),
          }, { headers });
          this.uploadUuid = res.data.uuid;
          this.pollForResult();
        } else {
          this.loading = false;
          this.errorMessage = 'An error occurred while uploading the file.';
          this.hasError = true;
          this.hasSuccess = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.errorMessage = 'An error occurred while uploading the file.';
        this.hasError = true;
        this.hasSuccess = false;
      }
    },
    pollForResult(index = 0, clic = false) {
      let pollingAttempts = 0;
      const maxPollingAttempts = 80; // 4 minutes divided by 3 seconds = 80 attempts
      const intervalId = setInterval(async () => {
        pollingAttempts++;

        // Get Cognito token for authentication
        const token = await this.getCognitoToken();

        // Headers for the request
        const headers = {
          'Authorization': token,
        };

        try {
          const response = await axios.post(`https://2e3dadnbej.execute-api.us-east-1.amazonaws.com/dev/runs/results`,
          { uuid: this.uploadUuid},
          { headers });

          // Check if some condition is met to stop the polling (like successful result received)
          this.runResults = response.data[0];
          if (response.data[0][index] && response.data[0][index].Success) {
            if (clic) {
              await axios.post(`https://2e3dadnbej.execute-api.us-east-1.amazonaws.com/dev/trigger/clic`,
                { schedule_id: "74882" },
                { headers });
            }

            this.loading = false;
            this.successMessage = 'File successfully uploaded!';
            this.hasSuccess = true;
            this.hasError = false;
            clearInterval(intervalId);
          }
        } catch (error) {
          this.loading = false;
          this.errorMessage = 'An error occurred while uploading the file.';
          this.hasError = true;
          this.hasSuccess = false;
          console.log('An error occurred while polling for result:', error);
        }

        // Stop polling after 4 minutes
        if (pollingAttempts >= maxPollingAttempts) {
          clearInterval(intervalId);
        }

      }, 3000); // Poll every 3 seconds
    },
    async transformData() {
      this.loading = true;

      try {
        const token = await this.getCognitoToken();

        if (!token) {
          this.errorMessage = 'Unable to get authentication token.';
          this.hasError = true;
          this.hasSuccess = false;
          return;
        }

        const headers = {
          'Authorization': token,
          'Content-Type': 'text/csv'
        };
        const response = await axios.post('https://2e3dadnbej.execute-api.us-east-1.amazonaws.com/dev/transform', { uuid: this.uploadUuid},
        { headers });        // Check if response is successful
        if (response.status === 201) {
          this.pollForResult(1, true);
        } else {
          this.loading = false;
          this.errorMessage = 'An error occurred while triggering transform.';
          this.hasError = true;
          this.hasSuccess = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.errorMessage = 'An error occurred while triggering transform.';
        this.hasError = true;
        this.hasSuccess = false;
      }
    },
    formatDate(date) {
      const inputDate = new Date(date);

      const year = inputDate.getFullYear();
      const month = String(inputDate.getMonth() + 1).padStart(2, '0');
      const day = String(inputDate.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`
    }
  },
};
</script>

<style>

.dp__action_select {
  background-color: rgb(59, 130, 246) !important;
}

</style>