<template>
    <div v-if="resultsArray">
      <div v-for="(result, index) in resultsArray" :key="index">
        <div v-html="formatResults(result.Results)"></div>
        <hr>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      resultsArray: {
        type: Array,
        required: true
      }
    },
    methods: {
      formatResults(str) {
        if (!str) {
        return "Waiting for results...";
        }
        return str.replace(/\r\n/g, '<br>');
      }
    }
  }
  </script>
  